import parsePhoneNumberFromString from 'libphonenumber-js/max';
import moment from 'moment';
import {SchemaLike} from 'yup/lib/types';

import {alphanumeric, integerNumbersRegExp} from '@/constants/RegExp';
import {DATE_FULL_TIME_WITH_TIMEZONE, ISO_LOCAL_DATE_TIME} from '@/constants/timeFormats';
import ValidationMessages from '@/constants/ValidationMessages';
import {ReferralDatesErrors} from '@/types/commonTypes';
import {
    ActivityDetailsDTO,
    Address,
    EducationDocumentFileDTO,
    ModificationRequestActivityModel,
    OrderActivityDTO,
    PersonnelDTO,
    PodDTO,
} from '@/types/gatewayDataModels';
import {
    durationToSeconds,
    formatDateTimeFromGivenToUTC,
    formatDateTimeToPatient,
    getDateTimeFormats,
    isSameOrBefore,
} from '@/utils/timeFormatter';

type DateParam = moment.MomentInput;

const {DATE_FORMAT, NEW_DATE_TIME_FORMAT} = getDateTimeFormats();

const checkPlural = <T extends number>(value: T): `${T} ${'Day' | 'Days'}` => {
    if (value === 1) return `${value} Day`;
    return `${value} Days`;
};

const base64ToBlob = (base64Img: string, contentType: Blob['type']): Blob => {
    // window.atob forces dom typing instead of node.js typing
    const byteCharacters = window.atob(base64Img);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    return new Blob([byteArray], {type: contentType});
};

const validatePhoneNumber = (inputValue: string): boolean => {
    if (!inputValue) {
        return false;
    }

    const phoneNumber = parsePhoneNumberFromString(inputValue, 'US');

    return phoneNumber && phoneNumber.isValid();
};

const validateLocalPhoneNumber = (inputValue: string): boolean => {
    const isValidPhoneNumber = validatePhoneNumber(inputValue);
    // Early return for previous line would prevent unecessary regex computation
    const isInt = /^\d+$/.test(inputValue);

    return isValidPhoneNumber || (isInt && inputValue.length < 20);
};

const validateAlphanumericString = (inputValue: string): boolean => {
    return alphanumeric.test(inputValue);
};

type FieldMap = Record<string, boolean>;
type FieldMock = {fields: {[K: string]: FieldMock}; tests: {name: string}[]};
// TODO: investigate issue related to 'when' - https://bitbucket.org/medicallyhome/mh-ui/pull-requests/6346
// Newer versions of Yup allow more flexible describes, which may be helpful with 'when' - https://github.com/jquense/yup#schemadescribeoptions-resolveoptions-schemadescription
const isRequiredField = (validationSchema: SchemaLike): FieldMap => {
    // Interesting discusson on why validationSchema used to be any: https://bitbucket.org/medicallyhome/mh-ui/pull-requests/5889#comment-325989966

    const fieldsMap: FieldMap = {};

    // for some reason, ts won't pick up describe type and will treat it as any
    const description = validationSchema.describe() as {fields: {[key: string]: FieldMock}};

    Object.entries(description.fields).forEach(([fieldName, field]) => {
        if (field.fields) {
            Object.keys(field.fields).forEach((fieldName) => {
                fieldsMap[fieldName] = !!field.fields[fieldName].tests.find((testName) => testName.name === 'required');
            });
        } else {
            fieldsMap[fieldName] = !!field.tests.find((testName) => testName.name === 'required');
        }
    });

    return fieldsMap;
};

type ElementSelector<Data, R = any> = (element: Data) => R;
type ObjectSelector<Data> = Data extends object ? keyof Data : never;

type DataSelector<Data> = ObjectSelector<Data> | ElementSelector<Data>;

type DefaultDataSelector<Data, Key extends keyof Data> = ElementSelector<Data, Data extends object ? Data[Key] : Data>;

type DataSelectorResult<Data, Selector> =
    Selector extends ObjectSelector<Data>
        ? Data[Selector]
        : Selector extends ElementSelector<Data, infer R>
          ? R
          : never;

const transformToOptions = <
    List extends any[],
    Data extends List[number],
    Label extends DataSelector<Data> = DefaultDataSelector<Data, 'label'>,
    Value extends DataSelector<Data> = DefaultDataSelector<Data, 'value'>,
>(
    data: List,
    params?: {
        label?: Label;
        value?: Value;
    },
): {
    label: DataSelectorResult<Data, Label>;
    value: DataSelectorResult<Data, Value>;
}[] => {
    if (!data) return null;

    return data.map((element) => {
        const normaliseParam = (paramKey: keyof NonNullable<typeof params> & ('label' | 'value')) => {
            const param = params?.[paramKey];

            if (!param) {
                return element;
            } else if (typeof param === 'function') {
                return param(element);
            } else {
                return element[param];
            }
        };

        return {
            label: normaliseParam('label'),
            value: normaliseParam('value'),
        };
    });
};

const formatLabel = (label: string): string =>
    label ? label.charAt(0).toUpperCase() + label.slice(1).toLowerCase() : '';

const getPodName = (pod: PodDTO): string => `${pod.cluster.name} - ${pod.name}`;

const getServiceCoordinatorName = (serviceCoordinator: PersonnelDTO) =>
    `${serviceCoordinator.firstName} ${serviceCoordinator.lastName}`;

const downloadFile = (fileName: string, blob: Blob): void => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    a.remove();
};

const getFormattedAddress = (address: Address): string => {
    if (!address) return '-';

    const {addressLine1, city, state, zipCode} = address;

    return [addressLine1, city, state, zipCode].filter((a) => a).join(', ');
};

const formatActivityLabel = <Label extends string, InDayOccurrence extends number, OrderInDayOccurrence extends number>(
    label: Label,
    inDayOccurrence: InDayOccurrence,
    orderInDayOccurrence: OrderInDayOccurrence,
): Label | `${InDayOccurrence} of ${OrderInDayOccurrence}` | `${InDayOccurrence}` | '' => {
    if (label) return label;

    if (inDayOccurrence) {
        if (orderInDayOccurrence) {
            return `${inDayOccurrence} of ${orderInDayOccurrence}`;
        } else {
            return `${inDayOccurrence}`;
        }
    }

    return '';
};

//removes UUID + _ from file name
const getFileNameWithoutUUID = (fileName: string): string =>
    fileName.replace(/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}_/, '');

const getEducationalDocumentLanguagesArray = (educationDocumentFiles: EducationDocumentFileDTO[]): string[] => [
    ...new Set(educationDocumentFiles.map((item) => item.language?.name)),
];

const checkIfNumber = (value: string) => /^\d+$/.test(value);

const camelCaseToReadable = (str: string) =>
    str
        .match(/^[a-z]+|[A-Z][a-z]+|&*/g)
        .map((x) => x.charAt(0).toUpperCase() + x.substring(1).toLowerCase())
        .join(' ');

type RecordWithId = {id: string};
type EntityMap = Record<string, RecordWithId> | RecordWithId[];

const idEquals = (firstEntity: RecordWithId, secondEntity: RecordWithId): boolean => {
    return firstEntity?.id === secondEntity?.id;
};

const mapIdEquals = (firstEntityMap?: EntityMap, secondEntityMap?: EntityMap): boolean => {
    // Just to make sure one is not null and the other undefined or some other nonesense like that
    if (!firstEntityMap) return !!secondEntityMap;
    if (!secondEntityMap) return !!firstEntityMap;

    // We want to bail out of execution as soon as we encounter one different id
    if (Array.isArray(firstEntityMap) && Array.isArray(secondEntityMap)) {
        for (const index of firstEntityMap.keys()) {
            const firstEntity = firstEntityMap[index];
            const secondEntity = secondEntityMap[index];

            if (!idEquals(firstEntity, secondEntity)) return false;
        }
    } else if (!Array.isArray(firstEntityMap) && !Array.isArray(secondEntityMap)) {
        for (const key in firstEntityMap) {
            const firstEntity = firstEntityMap[key];
            const secondEntity = secondEntityMap[key];

            if (!idEquals(firstEntity, secondEntity)) return false;
        }
    }

    // Just to make sure both are of the same type. If they are and no differences were found in ids,
    // true will be returned.
    return Array.isArray(firstEntityMap) === Array.isArray(secondEntityMap);
};

const getDecimalNumbersRegExp = (decimalPlaces: number) => {
    if (decimalPlaces === 0) return integerNumbersRegExp;
    return new RegExp(`^\\d+[\\.|\\,]?\\d{0,${decimalPlaces}}$`);
};

const roundDecimals = (number: number, decimals: number) => {
    const d = Math.pow(10, decimals);
    return Math.round((number + Number.EPSILON) * d) / d;
};

// https://stackoverflow.com/questions/105034/how-do-i-create-a-guid-uuid
const uuidv4 = (): string => {
    const template = `${1e7}-${1e3}-${4e3}-${8e3}-${1e11}`;
    return template.replace(/[018]/g, (c) =>
        (+c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (+c / 4)))).toString(16),
    );
};

const calculateTotalDuration = <T extends ActivityDetailsDTO | OrderActivityDTO | ModificationRequestActivityModel>(
    data: T[],
) => {
    const isActivityDetailsDTO = (
        item: ActivityDetailsDTO | OrderActivityDTO | ModificationRequestActivityModel,
    ): item is ActivityDetailsDTO => {
        return (
            item.hasOwnProperty('duration') &&
            item.hasOwnProperty('secondaryDuration') &&
            item.hasOwnProperty('activityCode')
        );
    };

    const activitiesList: string[] = [];

    return data.reduce((totalValue, item) => {
        let expectedDuration, expectedDurationSecondary, code;

        if (isActivityDetailsDTO(item)) {
            expectedDuration = item.duration;
            expectedDurationSecondary = item.secondaryDuration;
            code = item.activityCode;
        } else {
            expectedDuration = item.specification.expectedDuration;
            expectedDurationSecondary = item.specification.expectedDurationSecondary;
            code = item.specification.code;
        }

        let durationValue = expectedDuration;

        if (activitiesList.includes(code)) {
            durationValue = expectedDurationSecondary;
        } else {
            activitiesList.push(code);
        }

        totalValue += durationToSeconds(durationValue);

        return totalValue;
    }, 0);
};

const getCancelledMessage = (
    openEnded: boolean,
    cancelEffectiveDateTime: string,
    showPending: boolean,
    patientTimeZone?: string,
) =>
    openEnded
        ? `Cancelled effective ${formatDateTimeToPatient(
              cancelEffectiveDateTime,
              DATE_FORMAT,
              DATE_FULL_TIME_WITH_TIMEZONE,
              patientTimeZone,
          )}${showPending ? ', pending acknowledgment' : ''}`
        : `Cancelled${showPending ? ', pending acknowledgment' : ''}`;

function encodeString(str: string): string {
    return str
        .split('')
        .map((char) => char.charCodeAt(0).toString())
        .join(' ');
}
function decodeString(encodedStr: string): string {
    return encodedStr
        .split(' ')
        .map((code) => String.fromCharCode(Number(code)))
        .join('');
}
function encodeObjectKeys<T = unknown>(obj: Record<string, T>): Record<string, T> {
    const encodedObject: Record<string, T> = {};
    for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
            const encodedKey = encodeString(key);
            encodedObject[encodedKey] = obj[key];
        }
    }
    return encodedObject;
}
function decodeObjectKeys<T = unknown>(encodedObj: Record<string, T>): Record<string, T> {
    const decodedObject: Record<string, T> = {};
    for (const key in encodedObj) {
        if (encodedObj.hasOwnProperty(key)) {
            const decodedKey = decodeString(key);
            decodedObject[decodedKey] = encodedObj[key];
        }
    }
    return decodedObject;
}

const areReferralDatesValid = (referralSentDate: DateParam, referralAcceptedDate: DateParam, format?: string) => {
    if (!referralSentDate || !referralAcceptedDate) {
        return true;
    }

    return isSameOrBefore(referralSentDate, referralAcceptedDate, format || ISO_LOCAL_DATE_TIME);
};

function validateReferralDates(
    field: 'referralSentDate' | 'referralAcceptedDate',
    updatedValue: string,
    relatedValue: string,
    patientTimeZone: string,
    pendingValues?: {referralSentDate?: string; referralAcceptedDate?: string},
): ReferralDatesErrors {
    const utcValue =
        updatedValue &&
        formatDateTimeFromGivenToUTC(updatedValue, ISO_LOCAL_DATE_TIME, NEW_DATE_TIME_FORMAT, patientTimeZone);
    let areDatesValid;

    switch (field) {
        case 'referralSentDate': {
            const acceptedDate = pendingValues?.referralAcceptedDate
                ? formatDateTimeFromGivenToUTC(
                      pendingValues.referralAcceptedDate,
                      ISO_LOCAL_DATE_TIME,
                      NEW_DATE_TIME_FORMAT,
                      patientTimeZone,
                  )
                : relatedValue;
            areDatesValid = areReferralDatesValid(utcValue, acceptedDate);
            break;
        }
        case 'referralAcceptedDate': {
            const sentDate = pendingValues?.referralSentDate
                ? formatDateTimeFromGivenToUTC(
                      pendingValues.referralSentDate,
                      ISO_LOCAL_DATE_TIME,
                      NEW_DATE_TIME_FORMAT,
                      patientTimeZone,
                  )
                : relatedValue;
            areDatesValid = areReferralDatesValid(sentDate, utcValue);
            break;
        }
    }

    return areDatesValid
        ? null
        : {
              referralSentDate: ValidationMessages.referralSentDate,
              referralAcceptedDate: ValidationMessages.referralAcceptedDate,
          };
}

export {
    areReferralDatesValid,
    base64ToBlob,
    calculateTotalDuration,
    camelCaseToReadable,
    checkIfNumber,
    checkPlural,
    decodeObjectKeys,
    decodeString,
    downloadFile,
    encodeObjectKeys,
    encodeString,
    formatActivityLabel,
    formatLabel,
    getCancelledMessage,
    getDecimalNumbersRegExp,
    getEducationalDocumentLanguagesArray,
    getFileNameWithoutUUID,
    getFormattedAddress,
    getPodName,
    getServiceCoordinatorName,
    idEquals,
    isRequiredField,
    mapIdEquals,
    roundDecimals,
    transformToOptions,
    uuidv4,
    validateAlphanumericString,
    validateLocalPhoneNumber,
    validatePhoneNumber,
    validateReferralDates,
};
